<template>
<div>
	<bar ref="bar" close="true" step="Escribir" :help="instructions"/>
	<play-verse ref="playVerse"/>
</div>
</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import playVerse from '../components/studyUtils/PlayVerse.vue'

export default {
  name: 'play-wrap',
  data() {
    return {
      countWords : 0 ,
      totalWords : 0 ,
      finished : false ,
      instructions : {
        title : 'Escribir en Modo Avanzado',
        text : 'Escribe cualquier palabra o frase que escuches y pulsa enter o enviar para corregirla, puedes enviar tantas palabras o frases como quieras dentro del tiempo de la prueba. Superarás esta prueba cuando aciertes las palabras que te indica la barra de progreso, normalmente serán un 60% de las palabras del poema, no te preocupes si no aciertas todas las palabras a la primera porque es prácticamente imposible conseguirlo, las palabras acertadas se guardarán para el siguiente intento.',
        titleSecondary : 'El modo avanzado',
        textSecondary : 'En el modo avanzado realizarás el ejercicio sobre el poema entero. Es prácticamente imposible superar el modo avanzado en un sólo intento, concéntrate en acertar el máximo de palabras cada vez, todas las palabras acertadas quedarán guardadas para otros intentos que realices después o incluso otro día.',
        key : 'instructionsAdvancedPlay'
      }
    }
  },
  components: {
  	bar,
    'play-verse' : playVerse
  },
  mounted(){

    this.resource = 'step/'+this.$store.state.step.id+'/audio'

    if(this.$store.state.fromStart){
      this.init()
    }

    let that = this
    this.$root.$on('onInitPlayVerse',function(){
      that.init()
    })
    this.$root.$on('sendCaptionsPlayVerse',function(data){
      that.sendCaptions(data.captions,data.id)
    })
    this.$root.$on('onFinishProgressBar',function(){
      that.$refs.playVerse.disableSend()
      that.finished = true
      if (that.$refs.playVerse.countSentences === 0) {
        that.finish()
      }
    })
    this.$root.$on('onFinishChronometer',function(){
      that.$refs.playVerse.disableSend()
      that.finished = true
      if (that.$refs.playVerse.countSentences === 0) {
        that.finish()
      }
    })
  },
  methods: {
    sendCaptions(captions,id) {
      this.$http.patch(this.$store.state.urlApi+'step/'+this.$store.state.step.id+'/attempt/'+this.$store.state.attemptId, {
          captions
        },
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(response => {
        let words = response.body.increaseWords
        this.countWords += words
        this.$refs.bar.setProgress(this.countWords , this.totalWords)
        this.$refs.playVerse.notifyCorrection(id , words)
        if (this.finished && this.$refs.playVerse.countSentences === 0){
          this.finish()
        }
      }, function () {
        this.$refs.playVerse.notifyCorrection(id , 0)
        if (this.finished && this.$refs.playVerse.countSentences === 0){
          this.finish()
        }
      });
    },
    init: function(){
      this.$store.state.fromStart = true
      this.$store.state.loading = true
      this.$http.post(this.$store.state.urlApi+'step/'+this.$store.state.step.id+'/attempt', { },
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.accessToken
      }}).then(response => {
        this.$store.state.attemptId = response.body.id
        this.totalWords = parseInt(response.body.wordsToFinish)
        this.countWords = response.body.countWords
        this.$refs.bar.setProgress(this.countWords , this.totalWords)
  		  this.$refs.playVerse.initAudio(0,this.$store.state.step.duration,1,'step/'+this.$store.state.step.id+'/audio',response.body.calculatedCaptions,true)
      }, function() {
    		this.$store.state.loading = false
    		this.$store.state.refreshHome = true
    		this.$store.state.currentRoute = this.$store.state.errorRedirect
    		this.$router.push(this.$store.state.currentRoute)
      })
    },
    finish: function(){
      this.$http.patch(this.$store.state.urlApi+'step/'+this.$store.state.step.id+'/attempt/'+this.$store.state.attemptId, {
          opened: false,
          timezone: this.$store.state.timezone
        },
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }}).then(response => {

    		this.$store.state.result = {
            redirection : '/home' 
          , buttonName : 'continuar'
          , progressAdvanced : {
              increasePercentage: parseFloat(response.body.increase_percentage),
              finished: response.body.finished,
              increaseWords : parseInt(response.body.increase_words),
              words: parseInt(response.body.words),
              totalWords: this.totalWords
            },
            progressToday : {
              increaseWords : parseInt(response.body.increase_words),
              objetiveStreak : response.body.goalStreak
            },
            increaseCoins : parseInt(response.body.increase_coins)
    		}
    		this.$store.state.loading = false
    		this.$store.state.currentRoute = '/result'
    		this.$router.push(this.$store.state.currentRoute)

      }, function() {
    		this.$store.state.currentRoute = this.$store.state.errorRedirect
    		this.$router.push(this.$store.state.currentRoute)
      })

    }
  },
  beforeDestroy: function(){
    this.$root.$off('sendCaptionsPlayVerse')
    this.$root.$off('onFinishChronometer')
    this.$root.$off('onInitPlayVerse')
    this.$root.$off('onFinishProgressBar')
  }
}
</script>

<style>

</style>
